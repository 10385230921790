define("ember-concurrency/-private/external/scheduler/policies/bounded-policy", ["exports", "@babel/runtime/helpers/esm/classCallCheck"], function (_exports, _classCallCheck2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var BoundedPolicy = function BoundedPolicy(maxConcurrency) {
    (0, _classCallCheck2.default)(this, BoundedPolicy);
    this.maxConcurrency = maxConcurrency || 1;
  };

  var _default = BoundedPolicy;
  _exports.default = _default;
});