define("ember-changeset/helpers/changeset", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "ember-changeset", "validated-changeset"], function (_exports, _slicedToArray2, _emberChangeset, _validatedChangeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changeset = changeset;
  _exports.default = void 0;

  function changeset(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        obj = _ref2[0],
        validations = _ref2[1];

    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    if (!obj) {
      // route transitions may trigger this
      return;
    }

    if ((0, _validatedChangeset.isChangeset)(obj)) {
      return obj;
    }

    if ((0, _validatedChangeset.isObject)(validations)) {
      if ((0, _validatedChangeset.isPromise)(obj)) {
        return obj.then(function (resolved) {
          return (0, _emberChangeset.Changeset)(resolved, (0, _validatedChangeset.lookupValidator)(validations), validations, options);
        });
      }

      return (0, _emberChangeset.Changeset)(obj, (0, _validatedChangeset.lookupValidator)(validations), validations, options);
    }

    if ((0, _validatedChangeset.isPromise)(obj)) {
      return Promise.resolve(obj).then(function (resolved) {
        return (0, _emberChangeset.Changeset)(resolved, validations, {}, options);
      });
    }

    return (0, _emberChangeset.Changeset)(obj, validations, {}, options);
  }

  var _default = Ember.Helper.helper(changeset);

  _exports.default = _default;
});