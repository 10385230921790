define("ember-changeset/helpers/changeset-get", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.changesetGet = changesetGet;
  _exports.default = void 0;

  function changesetGet(_ref) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 2),
        changeset = _ref2[0],
        fieldPath = _ref2[1];

    return changeset.get(fieldPath);
  }

  var _default = Ember.Helper.helper(changesetGet);

  _exports.default = _default;
});